<template>
  <div>
    <PrebidNavBar />
    <v-container>
      <PlanHeader :title="'Questionnaire'" />
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="2"> </v-col>
            <v-col cols="2">
              <v-btn block @click="lockDIP()">
                <div v-if="$store.state.prebidPlan.PreBidPlan.Lock == true">
                  <span>Unlock DIP</span>
                  <v-icon right>mdi-lock-open-variant </v-icon>
                </div>
                <div v-else>
                  <span> Lock DIP </span> <v-icon right>mdi-lock </v-icon>
                </div>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                dark
                color="#E61E28"
                class="pa-4"
                @click="saveButtonOnClick()"
              >
                <v-icon left>mdi-check-circle-outline</v-icon> Save
              </v-btn>
            </v-col>

            <v-col cols="3"> </v-col>
            <v-col cols="3">
              Last modified by: <strong>{{ lastModified }}</strong>
            </v-col>
          </v-row>

          <div v-if="showWarning">
            <v-row>
              <v-col cols="4">
                <v-alert
                  dense
                  border="left"
                  color="grey darken-1"
                  type="warning"
                >
                  You are on the UAT / Test server!
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <!--                     QUESTION 1                     -->

      <v-card class="pa-9">
        <v-form ref="q1" v-model="form.q1.valid">
          <v-row>
            <v-col cols="6">
              <v-col cols="12">
                <h2 style="font-weight: normal">
                  1. Have you considered Digital Opportunities that:
                </h2>
                <br />
                <h3 style="font-weight: normal">
                  - Differentiate your bid? <br />
                  - Increase your chance of winning?<br />
                  - Increase your profit margin?
                </h3>

                <v-divider class="my-5"></v-divider>
                <v-btn
                  block
                  dark
                  dense
                  color="#E61E28"
                  href="https://arup.sharepoint.com/:l:/r/teams/global-design-automation-team/Lists/DIP%20Group%20Champions?e=s3wJ44"
                  target="_blank"
                  >LINK TO DIGITAL CHAMPIONS</v-btn
                >
              </v-col>
            </v-col>

            <v-col cols="5">
              <v-textarea
                :rules="textAreaRules"
                rows="4"
                filled
                class="mb-16"
                no-resize
                v-model="form.q1.description"
                placeholder="Add text here. If unsure, we suggest you reach out to your digital champion"
                color="#E61E28"
                required
              >
              </v-textarea>
              <br />
              <br />
              <OwnerSelector
                :prebid="true"
                :label="'Select an Action Owner...'"
                :placeholder="'Click here to assign an action owner for this initiative'"
                :firstQuestion="true"
                :reset="form.q1.reset"
                @update:owner="updateOwner($event, form.q1)"
              />
            </v-col>

            <v-col cols="1">
              <v-btn
                block
                style="min-height: 100%"
                color="#E61E28"
                :disabled="!validationCheck(form.q1.valid, form.q1.owner)"
                @click="addOpportunities"
              >
                <v-icon x-large color="#fff">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DigitalOpportunities />
            </v-col>
          </v-row>
        </v-form>

        <v-divider class="my-16"></v-divider>

        <!--                     QUESTION 2                     -->

        <v-form ref="q2" v-model="form.q2.valid">
          <v-row>
            <v-col cols="6">
              <v-col cols="12">
                <h2 style="font-weight: normal">
                  2. What actions have come from your discussion with your
                  group's digital champions?
                </h2>
                <br />
                <h3 style="font-weight: normal">
                  - If your project fee is over £2m GBP (or equivalent) you
                  should discuss with your regional leaders.
                </h3>
              </v-col>

              <v-col class="my-1" cols="12">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <v-btn
                  block
                  dark
                  color="#E61E28"
                  href="https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Automation-Team.aspx"
                  target="_blank"
                >
                  <span> Link to Regional Automation Leads</span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  dark
                  color="#E61E28"
                  href="https://arup.sharepoint.com/sites/DigitalTransformation/SitePages/Data.aspx#data-leadership-team"
                  target="_blank"
                >
                  <span> Link to Regional Data Leads</span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  dark
                  color="#E61E28"
                  href="https://arup.sharepoint.com/sites/Digital-Services/SitePages/Global-Leadership-Network.aspx?OR=Teams-HL&CT=1653913913935&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA1MDEwMTAwOSJ9"
                  target="_blank"
                >
                  <span> Link to Regional Digital Services Leads</span>
                </v-btn>
              </v-col>
            </v-col>

            <v-col cols="5">
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    rows="1"
                    v-model="form.q2.topicTitle"
                    no-resize
                    label="Topic title"
                    placeholder="Give your discussion a title..."
                    persistent-placeholder
                    color="#E61E28"
                    class="mb-n7"
                    :rules="textFieldRules"
                  >
                  </v-textarea>

                  <br />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="form.q2.topicType"
                    :items="['Data', 'Digital Services', 'Automation']"
                    label="Topic Type"
                    color="#E61E28"
                    :rules="selectRules"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    rows="4"
                    filled
                    class="mb-16"
                    no-resize
                    v-model="form.q2.discussion"
                    placeholder="Discuss what you will do..."
                    color="#E61E28"
                    :rules="textAreaRules"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <OwnerSelector
                    :label="'Digital Expert'"
                    :prebid="true"
                    :reset="form.q2.resetExpert"
                    @update:owner="updateDigitalExpert($event, form.q2)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <OwnerSelector
                    :label="'Action Owner'"
                    :prebid="true"
                    :reset="form.q2.reset"
                    @update:owner="updateOwner($event, form.q2)"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="1">
              <v-btn
                block
                style="min-height: 100%"
                color="#E61E28"
                :disabled="!validationCheck(form.q2.valid, form.q2.owner)"
                @click="addActions"
              >
                <v-icon x-large color="#fff">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <PlannedActions />
            </v-col>
          </v-row>
        </v-form>

        <v-divider class="my-16"></v-divider>

        <!--                     QUESTION 3                     -->

        <v-form ref="q3" v-model="form.q3.valid">
          <v-row>
            <v-col cols="6">
              <v-col cols="12">
                <h2 style="font-weight: normal">
                  3. Take a look at the Digital Value stories for inspiration on
                  what you could do with your project.
                </h2>
                <br />
                <h3 style="font-weight: normal">
                  - Record value stories of interest and your plan to include
                  them in your bid
                </h3>

                <v-divider class="my-5"></v-divider>

                <v-btn
                  dark
                  block
                  color="#E61E28"
                  href="https://arup.sharepoint.com/sites/ValueStories"
                  target="_blank"
                  >link to value story</v-btn
                >
              </v-col>
            </v-col>

            <v-col cols="5">
              <v-textarea
                no-resize
                rows="3"
                filled
                placeholder="Describe what you will do..."
                v-model="form.q3.description"
                color="#E61E28"
                :rules="textAreaRules"
              ></v-textarea>
              <br />
              <br />
              <br />
              <v-textarea
                no-resize
                label="Link to value story"
                rows="1"
                v-model="form.q3.valueStoryLink"
                color="#E61E28"
                :rules="urlRules"
              ></v-textarea>
              <OwnerSelector
                :prebid="true"
                :label="'Action Owner'"
                :reset="form.q3.reset"
                @update:owner="updateOwner($event, form.q3)"
              />
            </v-col>

            <v-col cols="1">
              <v-btn
                block
                style="min-height: 100%"
                color="#E61E28"
                :disabled="!validationCheck(form.q3.valid, form.q3.owner)"
                @click="addValueStories"
              >
                <v-icon x-large color="#fff">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ValueStories />
            </v-col>
          </v-row>
        </v-form>

        <v-divider class="my-16"></v-divider>

        <!--                     QUESTION 4                     -->
        <v-form ref="q4" v-model="form.q4.valid">
          <v-row>
            <v-col cols="6">
              <v-col cols="12">
                <h2 style="font-weight: normal">
                  4. Have a look at the “Ready to Go” Digital Services
                </h2>

                <v-divider class="my-5"></v-divider>
                <v-btn
                  dark
                  block
                  color="#E61E28"
                  target="_blank"
                  href=" https://arup.sharepoint.com/sites/Digital-Services/SitePages/Find-Digital-Services.aspx"
                  >link to ready to go solutions</v-btn
                >
              </v-col>
            </v-col>

            <v-col cols="5">
              <v-textarea
                no-resize
                rows="3"
                class="mb-16"
                filled
                v-model="form.q4.description"
                placeholder="Add text here, if unsure, reach out to your local digital champion."
                color="#E61E28"
              >
              </v-textarea>
              <OwnerSelector
                :prebid="true"
                :label="'Action Owner'"
                :reset="form.q4.reset"
                @update:owner="updateOwner($event, form.q4)"
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                block
                style="min-height: 100%"
                color="#E61E28"
                :disabled="!validationCheck(form.q4.valid, form.q4.owner)"
                @click="addReadyDigitalServices"
              >
                <v-icon x-large color="#fff">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col>
            <ReadyToGoServices />
          </v-col>
        </v-row>

        <v-divider class="my-16"></v-divider>

        <!--                     QUESTION 5                     -->
        <v-form ref="q5" v-model="form.q5.valid">
          <v-row>
            <v-col cols="6">
              <v-col cols="12">
                <h2 style="font-weight: normal">
                  5. Has the contract or proposed terms and conditions been
                  checked?
                </h2>
                <br />
                <h3 style="font-weight: normal">
                  - Are we developing IP that we need to protect or data we need
                  to reuse, and are we sure we know who owns it?<br /><br />
                </h3>

                <v-radio-group
                  row
                  v-model="form.q5.toggle"
                  :rules="radioButtonRules"
                >
                  <v-radio
                    label="Yes"
                    color="#E61E28"
                    @click="updateContract(true)"
                  ></v-radio>
                  <v-radio
                    label="No"
                    color="#E61E28"
                    @click="updateContract(false)"
                  ></v-radio>
                </v-radio-group>

                <v-alert
                  dense
                  border="left"
                  color="grey darken-1"
                  type="warning"
                >
                  By ticking 'Yes' you are stating that these issues are known
                  and understood by the Project Director that the issues will be
                  explored during the Digital Inception process.
                </v-alert>

                <v-divider class="my-5"></v-divider>

                <v-btn
                  dark
                  block
                  color="#E61E28"
                  href="https://arup.sharepoint.com/sites/essentials-ukimea-legal/SitePages/Digital.aspx"
                  target="_blank"
                  >More Information Here</v-btn
                >
              </v-col>
            </v-col>

            <v-col cols="5">
              <v-row justify="center">
                <v-col cols="12">
                  <v-textarea
                    no-resize
                    rows="4"
                    filled
                    class="mb-16"
                    placeholder="Add text here, if unsure, reach out to your local digital champion"
                    v-model="form.q5.description"
                    :rules="textAreaRules"
                    color="#E61E28"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12">
                  <OwnerSelector
                    :prebid="true"
                    :label="'Action Owner'"
                    :reset="form.q5.reset"
                    @update:owner="updateOwner($event, form.q5)"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-btn
                block
                style="min-height: 100%"
                color="#E61E28"
                :disabled="!validationCheck(form.q5.valid, form.q5.owner)"
                @click="addContract"
              >
                <v-icon x-large color="#fff">mdi-plus-circle-outline</v-icon>
              </v-btn></v-col
            >
          </v-row>
        </v-form>

        <v-row>
          <Contract />
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row>
          <v-col cols="10"> </v-col>
          <v-col cols="2">
            <v-btn dark block color="#E61E28" @click="navigate('PrebidActions')"
              >NEXT</v-btn
            >
          </v-col>
        </v-row>
      </v-card>

      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
import PlanHeader from "@/components/PlanHeader.vue";
import PrebidNavBar from "@/components/PrebidNavBar.vue";
import DigitalOpportunities from "@/components/DigitalOpportunities.vue";
import PlannedActions from "@/components/PlannedActions.vue";
import ValueStories from "@/components/ValueStories.vue";
import ReadyToGoServices from "@/components/ReadyToGoServices.vue";
import Contract from "@/components/Contract.vue";
import OwnerSelector from "@/components/OwnerSelector.vue";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  components: {
    PlanHeader,
    PrebidNavBar,
    DigitalOpportunities,
    PlannedActions,
    ValueStories,
    ReadyToGoServices,
    Contract,
    OwnerSelector,
  },

  data: () => ({
    loading: false,
    check: null,

    textAreaRules: [
      (v) => !!v || "A description is required",
      (v) =>
        (v && v.length <= 200) || "Description must be 200 characters or less",
    ],
    textFieldRules: [
      (v) => !!v || "Field cannot be empty",
      (v) => (v && v.length <= 100) || "Field must be 100 characters or less",
    ],
    selectRules: [(v) => !!v || "Please select from the dropdown menu"],
    urlRules: [(v) => !!v || "Please enter an URL link"],
    radioButtonRules: [(v) => v != null || ""],

    form: {
      q1: {
        description: "",
        owner: {
          Name: null,
          Email: null,
        },
        reset: false,
        valid: false,
      },
      q2: {
        topicTitle: "",
        topicType: "",
        discussion: "",
        digitalExpert: {
          Name: null,
          Email: null,
        },
        owner: {
          Name: null,
          Email: null,
        },

        reset: false,
        resetExpert: false,
        valid: false,
      },
      q3: {
        description: "",
        valueStoryLink: "",
        owner: {
          Name: null,
          Email: null,
        },
        reset: false,
        valid: false,
      },
      q4: {
        description: "",
        owner: {
          Name: null,
          Email: null,
        },
        reset: false,
        valid: false,
      },
      q5: {
        toggle: null,
        description: "",
        owner: {
          Name: null,
          Email: null,
        },
        reset: false,
        valid: false,
      },
    },
  }),
  methods: {
    updateOwner(owner, instance) {
      instance.owner = owner;
      instance.reset = false;
    },

    validationCheck(localData, componentData) {
      if (localData && componentData.Name) {
        return true;
      } else return false;
    },

    updateDigitalExpert(digitalExpert, instance) {
      instance.digitalExpert = digitalExpert;
      instance.resetExpert = false;
    },

    addOpportunities() {
      let newObj = {
        Id: this.$uuid.v4(),
        Owner: this.form.q1.owner,
        Opportunity: this.form.q1.textArea,
        Status: "Not Started",
        Due: new Date(
          Date.now() + 1209600000 - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      };

      this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunities.push(newObj);

      for (let x in this.form.q1) {
        this.form.q1[x] = "";
        this.form.q1.reset = true;
      }
      this.$refs.q1.resetValidation();
    },

    addActions() {
      let newObj = {
        Id: this.$uuid.v4(),
        Owner: this.form.q2.owner,
        Title: this.form.q2.textArea,
        Topic: this.form.q2.topicType,
        Expert: this.form.q2.digitalExpert,
        Discussion: this.form.q2.discussion,
        Status: "Not Started",
        Due: new Date(
          Date.now() + 1209600000 - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      };
      this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunityActions.push(
        newObj
      );

      for (let x in this.form.q2) {
        this.form.q2[x] = "";
      }
      this.form.q2.reset = true;
      this.$refs.q2.resetValidation();
    },

    addValueStories() {
      let newObj = {
        Id: this.$uuid.v4(),
        Owner: this.form.q3.owner,
        Link: this.form.q3.valueStoryLink,
        Description: this.form.q3.description,
        Status: "Not Started",
        Due: new Date(
          Date.now() + 1209600000 - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      };

      this.$store.state.prebidPlan.PreBidPlan.DigitalValueStories.push(newObj);

      for (let x in this.form.q3) {
        this.form.q3[x] = "";
      }
      this.form.q3.reset = true;
      this.$refs.q3.resetValidation();
    },

    addReadyDigitalServices() {
      let newObj = {
        Id: this.$uuid.v4(),
        Owner: this.form.q4.owner,
        Description: this.form.q4.description,
        Status: "Not Started",
        Due: new Date(
          Date.now() + 1209600000 - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      };

      this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices.push(newObj);

      for (let x in this.form.q4) {
        this.form.q4[x] = "";
      }
      this.form.q4.reset = true;
      this.$refs.q4.resetValidation();
    },

    updateContract(val) {
      this.$store.state.prebidPlan.PreBidPlan.ContractChecked = val;
    },

    addContract() {
      if (this.form.q5.toggle == 0) {
        this.check = "Yes";
      } else {
        this.check = "No";
      }

      let newObj = {
        Id: this.$uuid.v4(),
        Owner: this.form.q5.owner,
        Description: this.form.q5.description,
      };

      this.$store.state.prebidPlan.PreBidPlan.ContractActions.push(newObj);

      this.form.q5.toggle = null;
      this.form.q5.description = null;
      this.form.q5.owner = "";
      this.form.q5.reset = true;
      this.$refs.q5.resetValidation();
    },

    validate() {
      this.$refs.form.q1.validate();
    },

    lockDIP() {
      if (this.$store.state.prebidPlan.PreBidPlan.Lock == null) {
        this.$store.state.prebidPlan.PreBidPlan.Lock = true;
      } else {
        this.$store.state.prebidPlan.PreBidPlan.Lock =
          !this.$store.state.prebidPlan.PreBidPlan.Lock;
      }
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
    },
    saveButtonOnClick() {
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
      this.$session.set("planData", this.$store.state.prebidPlan);
    },
    navigate(routeName) {
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
      this.$session.set("planData", this.$store.state.prebidPlan);
      this.$router.push({ name: `${routeName}` });
    },
  },
  computed: {
    lastModified() {
      return this.$store.state.lastModified;
    },
    showWarning() {
      if (
        this.$store.state.ApiUri !=
        "https://dip-prod-function-app.azurewebsites.net/api"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  // mounted() {
  //   this.form.q5.toggle =
  //     this.$store.state.prebidPlan.PreBidPlan.ContractChecked;
  // },
};
</script>

<style src="../styles/prebidform.scss" lang="scss"></style>
